// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles'; // eslint-disable-line import/no-unresolved

// Vuetify
import { createVuetify } from 'vuetify';

const lightTheme = {
  dark: false,
  colors: {
    'icon-standard': '#777',
    'label-standard': '#ddd',
    'box-header': '#777',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
    },
  },
});
