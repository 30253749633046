<template>
  <v-dialog
    persistent
    :model-value="showOverlay != ''"
    min-width="60%"
    max-width="90%"
    min-height="60%"
    max-height="90%"
  >
    <v-card min-height="90vh">
      <v-card-title class="bgColorGreen text-white">
        <v-row>
          <v-col cols="10">
            {{
              showOverlay == 'AGB'
                ? 'Allgemeine Geschäftsbedingungen'
                : 'Datenschutzbestimmungen'
            }}
          </v-col>
          <v-col cols="2" class="text-right">
            <v-icon size="30" @click.stop="showOverlay = ''">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="overflow-auto">
        <Information :pagecontent="showOverlay" />
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="actionWarning"
    location="top"
    timeout="20000"
    color="rgb(117, 163, 165)"
    class="mySnack"
    >{{ actionWarningText }}
    <template #actions>
      <v-btn
        prepend-icon="mdi-close"
        density="compact"
        class="ml-3"
        color="white"
        variant="outlined"
        @click="actionWarning = false"
      >
        Schließen
      </v-btn>
    </template></v-snackbar
  >

  <v-form ref="anlageform">
    <v-card
      v-if="anlage && mode != '' && !editorMode.snack"
      width="100dvw"
      max-width="700px"
      height="100dvh"
      max-height="calc(100dvh - 20px)"
      elevation="10"
      class="theEditorForm ma-3"
    >
      <v-card-title v-if="anlageId"
        >Anlage ID {{ anlageId }}
        {{ mode === 'edit' ? 'bearbeiten' : 'löschen' }}</v-card-title
      >
      <v-card-title v-if="!anlageId">NEUE Anlage erfassen</v-card-title>
      <v-card-text class="pt-4">
        <v-sheet
          v-if="mode === 'add' && !UserData.id"
          border="md warning"
          class="pa-3 mb-3 bg-grey-lighten-4"
          >Hinweis: Sie sehen die Position ihrer Anlage als Kreis in der Karte.
          Sie können diesen durch Ziehen mit der Maus noch verschieben, nach dem
          Speichern sind Daten und Position nur mehr vom Sachbearbeiter
          änderbar!</v-sheet
        >

        <v-row
          v-if="mode === 'edit' && UserData.id && UserData.gemeinde"
          class="mb-4"
          :class="
            !anlage.freigegeben ? 'bg-red-lighten-1' : 'bg-green-lighten-1'
          "
          no-gutters
        >
          <v-col cols="2" class="pa-2 px-4"
            ><v-switch
              v-model="anlage.freigegeben"
              density="compact"
              hide-details
            ></v-switch
          ></v-col>
          <v-col
            cols="10"
            class="d-flex pa-2 px-4 text-white align-center justify-end"
            >{{
              !anlage.freigegeben
                ? 'Die Anlage ist nicht freigegeben'
                : 'Freigegeben und auch ohne Login sichtbar.'
            }}</v-col
          >
        </v-row>

        <v-card-subtitle class="pa-0 ma-0 text-black"
          >Anlagedaten</v-card-subtitle
        >
        <v-divider class="mb-4" />
        <v-row id="firstfield" no-gutters>
          <v-col cols="12" class="pr-1">
            <v-select
              v-model="anlage.art"
              :items="anlagenart"
              variant="solo"
              label="Art der Anlage"
              placeholder="Bitte wählen"
              :rules="rules.required"
              :disabled="mode === 'delete'"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <v-text-field
              v-model="anlage.nennleistung"
              variant="solo"
              label="Beantragte max. Nennleistung Anlage [kWp]"
              placeholder="kWp"
              :rules="rules.notzero"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <v-select
              v-model="anlage.einspeiseart"
              :items="einspeiseart"
              variant="solo"
              label="Einspeiseart"
              placeholder="Bitte wählen"
              :rules="rules.required"
              :disabled="mode === 'delete'"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <v-switch
              v-model="anlage.stromspeicher"
              :label="`Stromspeicher: ${anlage.stromspeicher ? 'JA' : 'NEIN'}`"
              color="rgb(117, 163, 165)"
              class="ml-5"
              :disabled="mode === 'delete'"
            ></v-switch>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-text-field
              v-if="anlage.stromspeicher"
              v-model="anlage.stromspeicherkapazitaet"
              label="Kapazität(kWh)"
              variant="solo"
              placeholder="kWh"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-subtitle class="pa-0 ma-0 text-black"
          >Antragsdaten Netzzugang</v-card-subtitle
        >
        <v-divider class="mb-4" />

        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <v-text-field
              v-model="anlage.antragsdatum"
              type="date"
              label="Antragsdatum"
              color="rgb(117, 163, 165)"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-text-field
              v-model="anlage.datumrueckmeldung"
              type="date"
              label="Datum Rückmeldung Netzbetreiber"
              color="rgb(117, 163, 165)"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model.number="anlage.einspeiseleistung"
              label="Zugesagte netzwirksame Einspeiseleistung [kW]"
              variant="solo"
              placeholder="kW"
              :rules="rules.positive"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="anlage.document && anlage.document.data"
          no-gutters
          class="mb-3"
        >
          <v-col cols="12"
            ><v-btn
              color="grey-lighten-3"
              prepend-icon="mdi-file-search"
              block
              :href="anlage.document.data.attributes.url"
              target="_blank"
              >Aktuelles Dokument:
              {{ anlage.document.data.attributes.name }}</v-btn
            ></v-col
          ></v-row
        >

        <v-row id="dokupload" no-gutters>
          <v-col cols="12">
            <v-file-input
              v-model="documents"
              accept="image/*, application/pdf"
              rows="4"
              variant="outlined"
              label="Dokument Netzzugang hochladen"
              show-size
              :disabled="mode === 'delete'"
              :rules="rules.requiredanlage"
            /> </v-col
        ></v-row>

        <v-card-subtitle class="pa-0 ma-0 text-grey"
          >Optionale Angaben</v-card-subtitle
        >
        <v-divider class="mb-4" />

        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <v-select
              v-model="anlage.errichtungsart"
              :items="errichtungsart"
              variant="solo"
              label="Art der Errichtung"
              placeholder="Bitte wählen"
              :disabled="mode === 'delete'"
            ></v-select>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-text-field
              v-model="anlage.anlagenflaeche"
              variant="solo"
              label="Anlagenfläche [m²]"
              placeholder="m²"
              :disabled="mode === 'delete'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea
              v-model="anlage.sonstiges"
              rows="4"
              variant="outlined"
              label="Sonstige Anmerkungen"
              :disabled="mode === 'delete'"
            /> </v-col
        ></v-row>
        <div v-if="mode !== 'delete' && (!UserData || !UserData.gemeinde)">
          <v-card-subtitle class="pa-0 ma-0 text-black"
            >Datenschutz / E-Mail</v-card-subtitle
          >
          <v-divider />
          <v-sheet class="bg-grey-lighten-4 pa-4">
            <p class="pb-4">
              Bitte geben Sie eine gültige E-Mail-Adresse ein – an diese wird
              ein Bestätigungslink gesendet, mit dem Ihr Eintrag zur
              Begutachtung freigeschalten wird. Die Freischaltung erfolgt durch
              die Gemeinde und kann einen längeren Zeitraum in Anspruch nehmen.
              Nach dem Absenden können die eigegeben Daten nicht mehr geändert
              werden. Ein Löschen ist jederzeit möglich.
            </p>
            <v-text-field
              v-if="!UserData.id"
              v-model="email"
              hide-details
              variant="solo"
              density="compact"
              label="e-mail"
            ></v-text-field>
            <v-row no-gutters class="pt-5">
              <v-col cols="1"
                ><v-checkbox
                  v-model="datenschutzOK"
                  density="compact"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col cols="11"
                >Ich bestätige mein Einverständnis mit den
                <a class="inlineLink" @click.stop="infoOverlay('Datenschutz')"
                  >Datenschutzbedingungen</a
                >.
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="calc(50% - 0.5rem)"
          color="red-darken-2"
          variant="flat"
          @click="closeEditor"
          >Abbrechen</v-btn
        >
        <v-spacer />
        <v-btn
          v-if="mode === 'delete' && anlageId"
          width="calc(50% - 0.5rem)"
          color="orange-darken-2"
          variant="flat"
          @click.stop="submitDelete"
          >Löschen</v-btn
        >
        <v-spacer v-if="UserData.id" />
        <v-btn
          v-if="mode !== 'delete' && (!anlageId || anlage._userCanUpdate)"
          :disabled="
            !UserData.id &&
            (!datenschutzOK || !email || !EMAIL_REGEX.test(email))
          "
          width="calc(50% - 0.5rem)"
          color="green-darken-3"
          variant="flat"
          @click="submitSave"
          >{{ UserData.id ? 'Speichern' : 'Absenden' }}</v-btn
        >
      </v-card-actions>
    </v-card></v-form
  >
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Information from './Information.vue';
import { useEditorMode } from '../composables/useEditorMode.js';
import { useLayers } from '../composables/useLayers.js';
import { useUserManage } from '../composables/useUserManage.js';
import { EMAIL_REGEX } from '../constants.js';

const { strapiSources } = useLayers();
const {
  editorMode,
  editableObject: anlage,
  editableObjectId: anlageId,
  documents,
  saveItem: saveAnlage,
  deleteItem: deleteAnlage,
  verifyNextItem,
} = useEditorMode('strapi:anlagen');

const { UserData } = useUserManage();

const einspeiseart = [
  'Volleinspeisung',
  'Überschusseinspeisung',
  'Inselbetrieb (keine Einspeisung)',
];

const rules = {
  required: [(value) => !!value || 'Dieser Wert muss eingegeben werden'],
  requiredanlage: [
    (value) => {
      if (!!value || (anlage.value.document && anlage.value.document.data)) {
        return true;
      }
      return 'Ein Dokument muss existieren!';
    },
  ],

  notzero: [(value) => value > 0 || 'Dieser Wert muss größer Null sein'],
  positive: [
    (value) => {
      if (value === '' || Number.isNaN(Number(value)) || value < 0) {
        return 'Dieser Wert muss Null oder größer sein';
      }
      return true;
    },
  ],
};
const anlagenart = ['Freifläche', 'Gebäudeintegriert', 'Agri-PV'];
const errichtungsart = [
  'Ohne Angabe',
  'Neuerrichtung',
  'Erweiterung',
  'Planung',
];

const anlageform = ref();
const actionWarning = ref(false);
const actionWarningText = ref('');
const email = ref('');
const datenschutzOK = ref(false);
const showOverlay = ref('');

const router = useRouter();

const mode = computed(() =>
  editorMode.value.mode === 'edit' || editorMode.value.mode === 'approve'
    ? 'edit'
    : editorMode.value.mode
);

function closeEditor() {
  actionWarning.value = false;
  datenschutzOK.value = false;
  editorMode.value.mode = '';
  strapiSources.value['strapi:anlagen'].refresh();
}

async function submitSave() {
  const validated = await anlageform.value.validate();
  if (validated.valid) {
    const result = await saveAnlage(email.value);
    if (!UserData.value.id && email.value) {
      router.push({ name: 'Linksent' });
    }
    if (result) {
      const continueApproving = editorMode.value.mode === 'approve';
      closeEditor();
      if (continueApproving) {
        editorMode.value.mode = 'approve';
        strapiSources.value['strapi:anlagen'].once('featuresloadend', () => {
          verifyNextItem();
        });
      }
    } else {
      actionWarningText.value =
        'Fehler beim Speichern der Anlage. Bitte erneut versuchen!';
    }
  } else if (
    anlage.value.art &&
    anlage.value.nennleistung &&
    anlage.value.nennleistung > 0
  ) {
    document.getElementById('dokupload').scrollIntoView();
  } else {
    document.getElementById('firstfield').scrollIntoView();
  }
}

async function submitDelete() {
  const result = await deleteAnlage();
  if (result) {
    closeEditor();
  } else {
    actionWarningText.value =
      'Fehler beim Löschen der Anlage. Bitte erneut versuchen!';
  }
}

function infoOverlay(what) {
  showOverlay.value = what;
}

watch(actionWarningText, () => {
  actionWarning.value = !!actionWarningText.value;
});
</script>

<style scoped>
.theEditorForm {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1100;
}

.theEditorForm .v-card-title {
  background-color: rgb(117, 163, 165);
  color: white;
}
.theEditorForm .v-card-text {
  height: calc(100% - 100px);
  overflow: auto;
}

.theEditorForm .v-card-actions {
  background-color: rgb(117, 163, 165);
}

.inlineLink {
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
</style>
